import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../config.service";
import { ToastService } from "../toast.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from '../app.component';
import { CookieService } from "../services/cookie/cookie.service";
import { InteractionService } from "../interaction.service";
import { UserCookieService } from "../services/User/UserCookie.service";

@Component({
  selector: "app-rise-up",
  templateUrl: "./rise-up.component.html",
  styleUrls: ["./rise-up.component.scss"],
})
export class RiseUpComponent implements OnInit {
  mobileGameList: any;
  desktopGameList: any;
  session: string;
  userId: any;
  src: string = "";
  loadedImages: { [key: string]: boolean } = {};
  isCollapseOpen = false;
  isArrowUp = false;
  provider: string;
  filteredDesktopGameList: any[];
  filteredMobileGameList: any[];
  isLogged: boolean = false;
  providersList = ["1x2", "Amatic", "Betsoft", "Bomba", "Booming", "Booongo", "Casino_Technology", "EGT", "Evoplay", "Gameart", "Gaminator", "Habanero", "Igrosoft",
              "IGT","IronDog", "ISoftBet", "Leap", "Merkur", "Netent", "Novomatic", "Octavian", "Playson", "Pragmatic", "Platipus",
              "Red Rake", "Skywind", "Spinmatic", "Spinomenal", "XPlosive", "YGGDrasil", "Zeus Play"];

  constructor(private configService: ConfigService, private toastService: ToastService, private translate: TranslateService, private appComponent: AppComponent, 
              private interactionService: InteractionService, private userCookieService: UserCookieService) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem("currentUser");
    this.getVideoSlotList("Pragmatic");
    this.provider = "Pragmatic";
    this.preloadImages();

    this.interactionService.isLogged
    .subscribe((isLogged: boolean) => {
      this.isLogged = isLogged;
    });
  }

  selectProvider(provider: string) {
    this.getVideoSlotList(provider);
    this.provider = provider;

    if (this.isCollapseOpen) {
      setTimeout(() => {
        this.isCollapseOpen = false;
      }, 0);
    }
  }

  toggleCollapse(): void {
    if (this.isCollapseOpen) {
      this.isCollapseOpen = false;
      this.isArrowUp = false;
    } else {
      this.isCollapseOpen = true;
      this.isArrowUp = true;
    }
  }

  filterByGames(value: string) {
    if (value.trim() === '') {
      this.filteredDesktopGameList = this.desktopGameList;
      this.filteredMobileGameList = this.mobileGameList;
    } else {
      this.filteredDesktopGameList = this.desktopGameList.filter((game: any) => game.name.toLowerCase().includes(value.toLowerCase()));
      this.filteredMobileGameList = this.mobileGameList.filter((game: any) => game.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  preloadImages(): void {
    for (const slot of this.providersList) {
      const img = new Image();
      img.src = `../../assets/image/slots/${slot}.png`;

      img.onload = () => {
        this.loadedImages[slot] = true;
      };

      img.onerror = () => {
        this.loadedImages[slot] = false;
      };
    }
  }

  getImageSource(slot: string): string {
    return `../../assets/image/slots/${slot}.png`;
  }

  checkImageExists(slot: string): boolean {
    return this.loadedImages[slot];
  }

  getVideoSlotList(provider: string) {
    this.configService
      .getRiseUpGameListByProvider(provider)
      .subscribe((gameList: any) => {
        this.desktopGameList = gameList.data.filter((x: any) => x.device === "desktop" || x.device === "html5");
        this.mobileGameList = gameList.data.filter((x: any) => x.device === "mobile");
        this.filteredDesktopGameList = this.desktopGameList;
        this.filteredMobileGameList = this.mobileGameList;
      });
  }

  openSlotSession(element: any) {
    const device = element.device;
    this.userId = this.userCookieService.getUserId();

    if (!this.isLogged && !this.userId) {
      this.toastService.showNotification({
        message: this.translate.instant("pg-home.must-be-logged"),
        type: "danger",
      });
      return;
    }

    if (device === "desktop" || device === "html5") {
      this.configService.getRiseUpGameLink(this.userId, element.id, "", device, element.name, this.provider)
        .subscribe((data: any) => {
          let desktopWindow = window.open('', "Slot", "width=1280, height=720");
          desktopWindow.window.document.body.style.margin = '0';
          desktopWindow.window.document.body.style.padding = '0';
          desktopWindow.window.document.title = element.name;
          desktopWindow.window.document.body.innerHTML = `<iframe style='width: 100%; height: 100%; border: none;' src='${data.data}'></iframe>`;
        });
    } else if (device === "mobile") {
      let mobilewindow = window.open();
      this.configService.getRiseUpGameLink(this.userId, element.id, "", device, element.name, this.provider)
        .subscribe((data: any) => {
          const gameLink = data.data;
          mobilewindow.window.document.body.style.margin = '0';
          mobilewindow.window.document.body.style.padding = '0';
          mobilewindow.window.document.title = element.name;
          mobilewindow.window.document.body.innerHTML = `<iframe style='width: 100%; height: 100%; border: none;' src='${gameLink}'></iframe>`;
        });
    };
  }

}
